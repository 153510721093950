import React from "react";
const CookiePolicy = () => {
  return (
    <>
      <div className="privacy-policy terms-and-condition container">
        <h1>Cookie Policy</h1>
        <div className="mt-3 mb-5">
          {/* <h2 className="mb-3">What is Lorem Ipsum?</h2> */}
          <p>
            This Cookie Policy explains how Insight uses cookies and similar
            tracking technologies when you use our app or visit our website.
            Please read this policy to understand how we use these technologies
            and your choices regarding them.
          </p>
          <br />
          <h3>1. What Are Cookies? </h3>
          <p>
            Cookies are small text files that are stored on your device
            (computer, smartphone, tablet) when you visit a website or use an
            app. Cookies help us recognize your device, store your preferences,
            and enhance your experience.
          </p>
          <br />
          <h3>2. Types of Cookies We Use</h3>
          <p>We use the following types of cookies:</p>
          <h3 className="option-alphabat">a. Essential Cookies</h3>
          <p>
            These cookies are necessary for the app or website to function
            properly. They enable basic features, such as account login and
            security.
          </p>
          <h3 className="option-alphabat">
            b. Performance and Analytics Cookies
          </h3>
          <p>
            These cookies help us understand how users interact with our app or
            website. We use them to analyze traffic, identify issues, and
            improve functionality.
          </p>
          <h3 className="option-alphabat">c. Functionality Cookies</h3>
          <p>
            These cookies remember your preferences, such as language or region,
            to provide a more personalized experience.
          </p>

          <h3>3. Data Retention</h3>
          <p>
            We retain information collected via cookies for as long as necessary
            to fulfill the purposes outlined in this policy, unless a longer
            retention period is required by law.
          </p>
          <br />
          <h3>Email:</h3>
          <p>info@insight.com</p>
          <div className="mt-2">
            <p>
              Thank you for using Insight! We’re excited to help you connect and
              grow your network.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export { CookiePolicy };
